import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';
import { Subscribe } from 'unstated';

import {
	AIEventsInstrumentationProvider,
	type AIEventsInstrumentationConfig,
	useAIEventsInstrumentation,
} from '@atlassian/ai-analytics';

import { Attribution, ErrorBoundary } from '@confluence/error-boundary';
import { ExperienceTrackerContext, AI_AUDIO } from '@confluence/experience-tracker';
import { FlagsStateContainer } from '@confluence/flags';

import { useAudioPlaybackState } from '../hooks/useAudioPlaybackState';

import { DraggableMiniplayer } from './DraggableMiniplayer';

const i18n = defineMessages({
	errorTitle: {
		id: 'audio.floating-miniplayer.error-title',
		defaultMessage: 'We couldn’t play the summary',
		description:
			'This title shows atop the error popup flag in the corner of the window when an error in audio playback occurs',
	},
	errorDescription: {
		id: 'audio.floating-miniplayer.error-description',
		defaultMessage:
			'We ran into a temporary issue with our audio player. Wait a minute, then try again',
		description:
			'This description shows in the body of the error popup flag in the corner of the window when an error in audio playback occurs',
	},
});

const aiAnalyticsConfig: AIEventsInstrumentationConfig = {
	product: 'confluence',
	subproduct: 'confluence',
	source: 'page',
	aiFeatureName: 'aiAudio',
	proactiveGeneratedAI: 0,
	userGeneratedAI: 1,
};

const FloatingMiniplayerBoundaryWrapped = () => {
	const [
		{ activeContent, audioContext, audioSrcUrl, isLoading, error },
		{ initialize: resetAudioState },
	] = useAudioPlaybackState();
	const intl = useIntl();
	const experienceTracker = useContext(ExperienceTrackerContext);
	const { trackAIInteractionInit, trackAIResultError } = useAIEventsInstrumentation();
	const hasLoaded = useRef(false);

	useEffect(() => {
		if (isLoading) {
			hasLoaded.current = true;
			experienceTracker.start({
				name: AI_AUDIO,
				attributes: {
					contentId: activeContent?.id,
				},
			});
			trackAIInteractionInit();
		} else if (hasLoaded.current) {
			if (error) {
				experienceTracker.fail({ name: AI_AUDIO, error });
				trackAIResultError({
					aiErrorMessage: error.message,
				});
			} else {
				experienceTracker.succeed({ name: AI_AUDIO });
			}
		}
	}, [
		activeContent,
		error,
		experienceTracker,
		isLoading,
		hasLoaded,
		trackAIInteractionInit,
		trackAIResultError,
	]);

	const showMiniplayer = useMemo(() => {
		return !!activeContent && !!audioContext && !!audioSrcUrl;
	}, [activeContent, audioContext, audioSrcUrl]);

	return (
		<>
			{showMiniplayer && <DraggableMiniplayer />}
			{error && (
				<Subscribe to={[FlagsStateContainer]}>
					{(flags: FlagsStateContainer) => {
						void resetAudioState();
						void flags.showErrorFlag({
							id: 'audio-error',
							title: intl.formatMessage(i18n.errorTitle),
							description: intl.formatMessage(i18n.errorDescription),
						});
						return null;
					}}
				</Subscribe>
			)}
		</>
	);
};

export const FloatingMiniplayer = () => (
	<ErrorBoundary attribution={Attribution.SMARTS}>
		<AIEventsInstrumentationProvider config={aiAnalyticsConfig}>
			<FloatingMiniplayerBoundaryWrapped />
		</AIEventsInstrumentationProvider>
	</ErrorBoundary>
);
