import React, { useEffect, useRef, useState } from 'react';

import { Box, xcss } from '@atlaskit/primitives';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import {
	draggable,
	dropTargetForElements,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { disableNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/disable-native-drag-preview';
import type { DragLocationHistory } from '@atlaskit/pragmatic-drag-and-drop/types';

import { Miniplayer } from './Miniplayer';

const miniplayerWidth = 300;
const initialTopPosition = 110;
const initialRightOffset = miniplayerWidth + 64; // width + space.800

const floatingStyles = xcss({
	width: `${miniplayerWidth}px`,
	position: 'fixed',
	top: `${initialTopPosition}px`, // under the top nav
	right: 'space.800', // Tied to initialRightOffset
	zIndex: 'modal',
	backgroundColor: 'elevation.surface.overlay',
	boxShadow: 'elevation.shadow.overlay',
	borderRadius: 'border.radius.300',
});

const idleStyles = xcss({
	cursor: 'grab',
});

const draggingStyles = xcss({
	cursor: 'grabbing',
	outlineWidth: 'border.width',
	outlineStyle: 'solid',
	outlineColor: 'color.border.selected',
});

type Position = {
	top: number;
	left: number;
};

// Prevents the miniplayer from being dragged past the boundaries of a given dimension of the window
const getBoundedDimension = (dimension: number, maxDimension: number): number => {
	if (dimension < 0 || dimension > maxDimension) {
		return dimension < 0 ? 0 : maxDimension;
	}

	return dimension;
};

// Prevent clicking from blurring/closing other UI elements like modals/dialogs
const stopClickPropagation = (event) => event.stopPropagation();

export const DraggableMiniplayer = () => {
	const draggableRef = useRef<HTMLElement>(null);
	const [isDragging, setIsDragging] = useState(false);
	const [initialPosition, setInitialPosition] = useState<Position>({
		top: initialTopPosition,
		left: document.body.clientWidth - initialRightOffset,
	});
	const [currentPosition, setCurrentPosition] = useState<Position>(initialPosition);
	const defaultCursorPosition = { top: 0, left: 0 };
	const [, setCurrentCursorPosition] = useState<Position>(defaultCursorPosition);

	const setPosition = (element: HTMLElement, location: DragLocationHistory, isDrop = false) => {
		let previousCursorPositionX: number = 0;
		let previousCursorPositionY: number = 0;
		setCurrentCursorPosition((current) => {
			previousCursorPositionY = current.top;
			previousCursorPositionX = current.left;

			return isDrop
				? defaultCursorPosition
				: {
						top: location.current.input.clientY,
						left: location.current.input.clientX,
					};
		});

		const clientYDelta = previousCursorPositionY - location.current.input.clientY;
		const clientXDelta = previousCursorPositionX - location.current.input.clientX;
		setCurrentPosition((current) => {
			let newTop = current.top - clientYDelta;
			let newLeft = current.left - clientXDelta;

			// Prevent dragging outside the window boundary
			newTop = getBoundedDimension(newTop, window.innerHeight - element.offsetHeight);
			newLeft = getBoundedDimension(newLeft, document.body.clientWidth - element.offsetWidth);

			element.style.top = `${newTop}px`;
			element.style.left = `${newLeft}px`;

			return {
				top: newTop,
				left: newLeft,
			};
		});
	};

	useEffect(() => {
		const element = draggableRef.current;
		if (!element) {
			return;
		}

		return combine(
			draggable({
				element,
				onGenerateDragPreview({ nativeSetDragImage }) {
					// We'll drag the actual element so hide the native drag preview
					disableNativeDragPreview({ nativeSetDragImage });
				},
				onDragStart: ({ location }) => {
					setCurrentCursorPosition({
						top: location.initial.input.clientY,
						left: location.initial.input.clientX,
					});
					setInitialPosition(currentPosition);
					setIsDragging(true);
				},
				onDrag: ({ location }) => {
					setPosition(element, location);
				},
				onDrop: ({ location }) => {
					setPosition(element, location, true);
					setIsDragging(false);
				},
			}),
			dropTargetForElements({
				element: document.body,
			}),
		);
	});

	return (
		<Box
			ref={draggableRef}
			xcss={[floatingStyles, isDragging ? draggingStyles : idleStyles]}
			onClick={stopClickPropagation}
			testId="audio-draggable-miniplayer"
		>
			<Miniplayer />
		</Box>
	);
};
