import type { FC } from 'react';
import React, { useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { IconButton } from '@atlaskit/button/new';
import VideoSkipBackwardTenIcon from '@atlaskit/icon/core/video-skip-backward-ten';
import VideoSkipForwardTenIcon from '@atlaskit/icon/core/video-skip-forward-ten';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import ShowMoreHorizontalIcon from '@atlaskit/icon/core/show-more-horizontal';
import VideoPreviousIcon from '@atlaskit/icon/core/video-previous';
import { Flex, Text } from '@atlaskit/primitives';
import VideoPauseOverlayIcon from '@atlaskit/icon/core/video-pause-overlay';
import VideoPlayOverlayIcon from '@atlaskit/icon/core/video-play-overlay';

import { MiniplayerPlaybackSpeedControl } from './MiniplayerPlaybackSpeedControl';

const i18n = defineMessages({
	skipBackButtonLabel: {
		id: 'audio.miniplayer-controls.skip-back-label',
		defaultMessage: 'Skip Back',
		description:
			'The label is used as a tooltip and for accessibility for the icon button which will skip the currently active audio back "x" seconds',
	},
	pauseButtonLabel: {
		id: 'audio.miniplayer-controls.pause-label',
		defaultMessage: 'Pause',
		description:
			'The label is used as a tooltip and for accessibility for the icon button which will pause the actively playing audio',
	},
	resumeButtonLabel: {
		id: 'audio.miniplayer-controls.resume-label',
		defaultMessage: 'Resume',
		description:
			'The label is used as a tooltip and for accessibility for the icon button which will resume the actively paused audio',
	},
	skipForwardButtonLabel: {
		id: 'audio.miniplayer-controls.skip-forward-label',
		defaultMessage: 'Skip Forward',
		description:
			'The label is used as a tooltip and for accessibility for the icon button which will skip the currently active audio forward "x" seconds',
	},
	moreButtonLabel: {
		id: 'audio.miniplayer-controls.more-label',
		defaultMessage: 'More',
		description:
			'The label is used as a tooltip and for accessibility for the icon button which will open additional playback controls for the active audio',
	},
	playFromBeginningLabel: {
		id: 'audio.miniplayer-controls.play-from-beginning-label',
		defaultMessage: 'Play from beginning',
		description:
			'The label is used for accessibility for the icon which will be shown in the menu item that will restart the active audio from the beginning',
	},
});

export type MiniplayerControlsProps = {
	isPlaying: boolean;
	onPause: () => void;
	onResume: () => Promise<void>;
	onSkipBack: () => void;
	onSkipForward: () => void;
	onChangePlaybackSpeed: (newSpeed: number) => void;
	onPlayFromBeginning: () => Promise<void>;
};

export const MiniplayerControls: FC<MiniplayerControlsProps> = ({
	isPlaying,
	onPause,
	onResume,
	onSkipBack,
	onSkipForward,
	onChangePlaybackSpeed,
	onPlayFromBeginning,
}) => {
	const intl = useIntl();

	const icon = useMemo(() => {
		return isPlaying ? (
			<IconButton
				icon={VideoPauseOverlayIcon}
				label={intl.formatMessage(i18n.pauseButtonLabel)}
				tooltip={{ content: intl.formatMessage(i18n.pauseButtonLabel) }}
				isTooltipDisabled={false}
				appearance="primary"
				shape="circle"
				testId="miniplayer-pause"
				onClick={onPause}
			/>
		) : (
			<IconButton
				icon={VideoPlayOverlayIcon}
				label={intl.formatMessage(i18n.resumeButtonLabel)}
				tooltip={{ content: intl.formatMessage(i18n.resumeButtonLabel) }}
				isTooltipDisabled={false}
				appearance="primary"
				shape="circle"
				testId="miniplayer-resume"
				onClick={onResume}
			/>
		);
	}, [intl, isPlaying, onPause, onResume]);

	return (
		<Flex justifyContent="space-evenly" alignItems="center">
			<MiniplayerPlaybackSpeedControl onChangePlaybackSpeed={onChangePlaybackSpeed} />
			<IconButton
				icon={VideoSkipBackwardTenIcon}
				shape="circle"
				label={intl.formatMessage(i18n.skipBackButtonLabel)}
				tooltip={{ content: intl.formatMessage(i18n.skipBackButtonLabel) }}
				isTooltipDisabled={false}
				appearance="subtle"
				testId="miniplayer-skip-back"
				onClick={onSkipBack}
			/>
			{icon}
			<IconButton
				icon={VideoSkipForwardTenIcon}
				shape="circle"
				label={intl.formatMessage(i18n.skipForwardButtonLabel)}
				tooltip={{ content: intl.formatMessage(i18n.skipForwardButtonLabel) }}
				isTooltipDisabled={false}
				appearance="subtle"
				testId="miniplayer-skip-forward"
				onClick={onSkipForward}
			/>
			<DropdownMenu<HTMLButtonElement>
				trigger={({ triggerRef, ...props }) => (
					<IconButton
						{...props}
						icon={ShowMoreHorizontalIcon}
						label={intl.formatMessage(i18n.moreButtonLabel)}
						tooltip={{ content: intl.formatMessage(i18n.moreButtonLabel) }}
						isTooltipDisabled={false}
						appearance="subtle"
						shape="circle"
						testId="miniplayer-more-controls"
						ref={triggerRef}
					/>
				)}
				shouldRenderToParent
				placement="bottom-end"
			>
				<DropdownItemGroup>
					<DropdownItem onClick={onPlayFromBeginning} testId="miniplayer-play-from-beginning">
						<Flex columnGap="space.100" alignItems="center">
							<VideoPreviousIcon label={intl.formatMessage(i18n.playFromBeginningLabel)} />
							<Text>{intl.formatMessage(i18n.playFromBeginningLabel)}</Text>
						</Flex>
					</DropdownItem>
				</DropdownItemGroup>
			</DropdownMenu>
		</Flex>
	);
};
